import { graphql } from "gatsby"
import SEO, { getSeoProps } from "../components/Common/SEO"
import AktueltCards from "../components/Cards/AktueltCards"
import HeroAktueltHeadline from "../components/Hero/HeroAktueltHeadline"
import getBannerProps from "../components/Hero/getHeroBannerProps"
import getMainPath from "../components/Common/MainPaths"
import { useState } from "react"
import { Paginator } from "../components/Search/Pagination"
import { SortByDate } from "../components/Common/DateConverter"

/**
 * @name Artikkel
 * @description Dette er "aktuelt" siden som viser artikler: Dvs nyhetssaker - ikke temasider
 * På denne siden skal vi ikke vise de artikler som krever innlogging.
 * Artikler skal vises i kronologisk artikkel basert på publisert dato kun.
 * Feature artikler skal ikke synes på denne siden.
 * Det skal være en tittel og intro tekst på denne siden
 */

const Aktuelt = ({ location, data }) => {
  const mainPath = getMainPath("article")
  const [filter, setFilter] = useState(null)
  const [page, setPage] = useState(1)

  const articlesPerPage = 11

  const {
    allContentfulArticle: { nodes: allContent },
    contentfulGeneric: content,
  } = data

  // Sort all related articles from new to old. Let GH override with createdAtModified
  const sortedContent = SortByDate(allContent)

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")
  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  // Applys filter
  let filteredContent = sortedContent.filter((article) => {
    if (!filter) return true
    return article.category?.id === filter.id
  })

  const numPages = Math.ceil(filteredContent.length / articlesPerPage)

  filteredContent = filteredContent.slice((page - 1) * articlesPerPage, articlesPerPage * page)

  // finds the first two featured Articles sorted på order (priority)
  const featuredArticles = filteredContent
    .filter((article) => article.articleType)
    .sort((a, b) => a.order - b.order)
    .slice(0, 2)

  // get all other articles, that is not in featuredArticles
  const standardArticles = filteredContent.filter((article) => {
    return !(article.id === featuredArticles[0]?.id || article.id === featuredArticles[1]?.id)
  })

  const categorys = sortedContent.map((article) => article.category).filter((cat) => cat?.id)
  const catIds = categorys.map((cat) => cat.id)

  const uniqueCategory = categorys.filter((category, index) => {
    return catIds.indexOf(category.id) === index
  })

  const handleChange = (e) => {
    if (e.target.value === "alle") {
      setFilter(null)
      return
    }
    setFilter(uniqueCategory.find(({ id }) => id === e.target.value))
  }

  return (
    <>
      <SEO {...helmetProps} />
      <main className="flex justify-center">
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 lg:max-w-7xl">
          <header>
            <HeroAktueltHeadline {...heroBannerProps} />
          </header>
          <div className="hidden lg:block p-6 mt-8 bg-transparent mx-auto max-w-4xl">
            <ul className="flex justify-around sm:flex-row sm:flex-wrap">
              <button
                className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:outline-none focus:border-transparent border-none hover:border-transparent rounded-full"
                role="menuitem"
                onClick={() => setFilter(null)}
                // autofocus="true"
              >
                Vis alle #
              </button>

              {uniqueCategory.map((category) => {
                return (
                  <button
                    key={category.id}
                    className="bg-transparent text-black active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500 font-normal py-2 px-4 focus:outline-none focus:border-transparent border-none hover:border-transparent rounded-full"
                    role="menuitem"
                    onClick={() => setFilter(category)}
                    // autofocus="true"
                  >
                    #{category.category}
                  </button>
                )
              })}
            </ul>
          </div>

          <div className="flex lg:hidden justify-center ">
            <select
              defaultValue={"DEFAULT"}
              onChange={handleChange}
              id="location"
              name="location"
              className="mt-1 w-auto text-base border-gray-300 hover:outline-none focus:outline-none  sm:text-sm  rounded-3xl bg-white squash-500 ring-1 ring-black  focus:ring-none"
            >
              <option value="alle">Vis alle #</option>
              {uniqueCategory.map((category) => {
                return (
                  <option key={category.id} value={category.id}>
                    {category.category}
                  </option>
                )
              })}
            </select>
          </div>

          <div className="flex justify-center">
            <div className="mt-12 max-w-lg mx-auto grid gap-24 lg:grid-cols-3 lg:max-w-none">
              {standardArticles
                .map((article) => {
                  const props = {
                    data: article,
                    url: "nb" + mainPath,
                    height: "lg:h-72",
                  }
                  return <AktueltCards key={article.id} {...props} />
                })
                .filter((article) => {
                  if (!filter) return true
                  return article.props.data.category.id === filter?.id
                })}
            </div>
          </div>
          <div className="mt-16">
            <Paginator maxPage={numPages} currentPage={page} changePage={(e) => setPage(e)} />
          </div>
        </div>
      </main>
    </>
  )
}

export const ariticleQuery = graphql`
  {
    allContentfulArticle(filter: { public: { eq: true }, node_locale: { eq: "nb-NO" }, articleType: { eq: false } }) {
      nodes {
        id
        public
        contentful_id
        title
        subtitle
        slug
        order
        articleType
        node_locale
        metadataDescription
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        createdAtModified(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        category {
          id
          category
          slug
        }
        tags {
          id
          title
        }
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
      }
    }
    contentfulGeneric(slug: { eq: "aktuelt" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      body {
        childMdx {
          timeToRead
          body
        }
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      node_locale
      slug
      title
      metadataDescription
    }
  }
`

export default Aktuelt
